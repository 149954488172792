import {post} from "../../stores/senders";
const queryString = require('query-string');

export const handleSearch = (
    source: {values: any, language: string, history: any},
    callback: any,
): void => {
    const {values, language, history} = source;
    let params = Object.assign({}, values);

    if (params.advancedSearch) params.rows = JSON.stringify(params.rows);
    else delete params.rows;

    post('/api/search/check', {language, values}, (response: any, error: boolean) => {
        callback();
        if (!error) {
            let url = '/search/' + queryString.stringify(params)
            history.push(url, {alreadyVerified: true})
        }
    })
}
