import React, {Suspense} from "react";
import {connect} from "react-redux";
import {HeaderProps} from "./index.d";

const DesktopHeader = React.lazy(() => import("./desktop")),
MobileHeader = React.lazy(() => import("./mobile"));

const HeaderConnect:React.FC<HeaderProps> = (props) => {
    if (props.isMobile) return (
        <Suspense fallback="">
            <MobileHeader {...props} />
        </Suspense>
    )
    else return (
        <Suspense fallback="">
            <DesktopHeader {...props} />
        </Suspense>
    )
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        appLanguage: store.state.appLanguage,
        languages: store.state.languages,
        variables: store.state.variables,
        menu: store.state.menu,
        setting: store.state.setting
    };
}, mapDispatch = (dispatch: any) => {
    return {
        fnChangeApplanguage: (data: any, callback: any) => dispatch.state.changeAppLanguage({data, callback}),
    };
}

export default connect(mapState, mapDispatch)(HeaderConnect);
