import React from "react";
import {Row, Col, Space, Select, Button, Checkbox} from 'antd';
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {RenderRowProps, IType} from "../index.d";

const {Option} = Select;
const DesktopRow: React.FC<RenderRowProps> = ({
    rows, sending, variables,
    handleChangeRow, handleRemoveRow, handleAddRow,
    getTypes, getLogicalOperations,
    renderComponentByType,
}) => {
    return (
        <div className="pt-10">
            {rows.map((row: any, idx: number) => {
                let isFirst: boolean = false;
                if (idx === 0) isFirst = true;

                // наименование, фамилия (запятую после фомилии), ключевое слово, место работы автора, DOI, ISSN, ORCID
                let visibleExactMatch = false;
                switch(row.type) {
                    case "author":
                    case "keywords":
                    case "denomination":
                    case "affiliation":
                    case "DOI":
                    case "ISSN":
                    case "ORCID":
                        visibleExactMatch = true;
                        break;
                }
                return (
                    <Row key={idx} className="mb-10">
                        <Col className="pr-10" span={4}>
                            <Select
                                className={isFirst?"hidden":""}
                                value={row.logicalOperations}
                                disabled={sending || isFirst}
                                onChange={(value: string) => handleChangeRow(idx, 'logicalOperations', value)}>
                                {getLogicalOperations().map((val: IType, idx: number) => {
                                    return <Option key={idx} value={val.value}>{val.name}</Option>
                                })}
                            </Select>
                        </Col>
                        <Col className="pr-10" span={7}>
                            <Select
                                disabled={sending}
                                value={row.type}
                                listHeight={32 * Object.keys(getTypes()).length}
                                onChange={(value: string) => handleChangeRow(idx, 'type', value)}>
                                {getTypes().map((val: IType, idx: number) => {
                                    return <Option key={idx} value={val.value}>{val.name}</Option>
                                })}
                            </Select>
                            {visibleExactMatch? (
                                <div className="element-exactMatch">
                                    <Checkbox checked={row.exactMatch} onChange={e=> handleChangeRow(idx, 'exactMatch', e.target.checked)}>{variables.exactMatch}</Checkbox>
                                </div>
                            ): null}
                        </Col>
                        {renderComponentByType(idx, row)}
                        <Col span={3} className="text-right">
                            <Space>
                                {(rows.length>1 && !isFirst)?(
                                    <Button
                                        disabled={sending}
                                        shape="circle"
                                        danger
                                        onClick={() => handleRemoveRow(idx)}
                                        icon={<MinusOutlined />} />
                                ):null}
                                <Button
                                    shape="circle"
                                    disabled={sending}
                                    onClick={() => handleAddRow(idx)}
                                    icon={<PlusOutlined />}/>
                            </Space>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default DesktopRow
