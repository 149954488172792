import React from "react";
import {Row, Col, Select, Button, Checkbox} from 'antd';
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {RenderRowProps, IType} from "../index.d";

const {Option} = Select;
const MobileRow: React.FC<RenderRowProps> = ({
    rows, sending, variables,
    handleChangeRow, handleRemoveRow, handleAddRow,
    getTypes, getLogicalOperations,
    renderComponentByType,
}) => {
    return (
        <div className="pt-10">
            {rows.map((row: any, idx: number) => {
                let isFirst: boolean = false;
                if (idx === 0) isFirst = true;

                let visibleExactMatch = false;
                switch(row.type) {
                    case "author":
                    case "keywords":
                    case "denomination":
                    case "affiliation":
                    case "DOI":
                    case "ISSN":
                    case "ORCID":
                        visibleExactMatch = true;
                        break;
                }

                return (
                    <Row key={idx} className="mb-10 row-filter">
                        {!isFirst?(
                            <Col className="mb-10 pr-10" span={8}>
                                <Select
                                    value={row.logicalOperations}
                                    disabled={sending || isFirst}
                                    onChange={(value: string) => handleChangeRow(idx, 'logicalOperations', value)}>
                                    {getLogicalOperations().map((val: IType, idx: number) => {
                                        return <Option key={idx} value={val.value}>{val.name}</Option>
                                    })}
                                </Select>
                            </Col>
                        ):null}
                        <Col className="mb-10" span={isFirst?24:16}>
                            <Select
                                disabled={sending}
                                value={row.type}
                                listHeight={32 * Object.keys(getTypes()).length}
                                onChange={(value: string) => handleChangeRow(idx, 'type', value)}>
                                {getTypes().map((val: IType, idx: number) => {
                                    return <Option key={idx} value={val.value}>{val.name}</Option>
                                })}
                            </Select>
                        </Col>
                        {visibleExactMatch? (
                            <Col className="mb-10" span={24}>
                                <Checkbox checked={row.exactMatch} onChange={e=> handleChangeRow(idx, 'exactMatch', e.target.checked)}>{variables.exactMatch}</Checkbox>
                            </Col>
                        ): null}
                        {renderComponentByType(idx, row)}
                        <Col className="mt-10" span={24}>
                            <Row>
                                <Col className="pr-5" span={12}>
                                    {(rows.length>1 && !isFirst)?(
                                        <Button
                                            disabled={sending}
                                            block
                                            danger
                                            onClick={() => handleRemoveRow(idx)}
                                            icon={<MinusOutlined />}>
                                            {variables.delete}
                                        </Button>
                                    ):null}
                                </Col>
                                <Col className="pl-5" span={12}>
                                    <Button
                                        block
                                        disabled={sending}
                                        onClick={() => handleAddRow(idx)}
                                        icon={<PlusOutlined />}>
                                        {variables.add}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default MobileRow
