import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import {Layout} from 'antd';

import AppHeader from '../partial/header';
import SearchScreen from '../screens/searchScreen';
import SearchResultScreen from '../screens/searchResultScreen';

import ProjectScreen from '../screens/projectScreen';
import ArticleScreen from '../screens/articleScreen';

import ProjectIndexScreen from 'components/screens/projectIndexScreen';

import JournalsScreen from "../screens/journalsScreen";
import ConferenceScreen from "../screens/conferenceScreen";
import BookScreen from "../screens/bookScreen";

import Child from './extra/child';
const {Header, Content} = Layout;

class Navigations extends Component<{}> {
    render() {
        return (
            <Router>
                <Layout>
                    <Header>
                        <AppHeader />
                    </Header>
                    <Content>
                        <Switch>
                            <Route path="/journals/:id?" children={<Child component={JournalsScreen} />} />
                            <Route path="/conf/:id?" children={<Child component={ConferenceScreen} />} />
                            <Route path="/books" children={<Child component={BookScreen} />} />
                            <Route path="/search/:data" children={<Child component={SearchResultScreen} />} />
                            {/* RADS index */}
                            <Route path="/project/:id/index" children={<Child component={ProjectIndexScreen} />} />
                            {/* Gefest */}
                            <Route path="/project/:journalId/object/:id" children={<Child component={ArticleScreen} />} />
                            <Route path="/project/:id" children={<Child component={ProjectScreen} />} />

                            <Route path="/" children={<Child component={SearchScreen} />} />
                        </Switch>
                    </Content>
                </Layout>
            </Router>
        )
    }
}

export default Navigations;
