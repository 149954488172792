import {parseError} from "./responseFn";
const axios = require('axios');

axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const post = (route: string, body: any, colback?: any) => {
    const source = axios.CancelToken.source();

    axios.create({
        cancelToken: source.token
    }).post(route, JSON.stringify(body))
    .then((response: any) => {
        if(colback && response?.data) {
            colback(response.data, false);
        }
    })
    .catch((error: any) => {
        if (error?.response?.data) {
            parseError(error.response.data);
        }
        if(colback) colback(null, true);
    })
    .then(function () {
        // Всегда выполняется
    });

    return source;
}


export const get = (route: string, body: any, colback?: any) => axios.get(route, JSON.stringify(body))
.then((response: any) => {
    if(colback && response?.data) {
        colback(response.data, false);
    }
})
.catch((error: any) => {
    if (error?.response?.data) {
        parseError(error.response.data);
    }
    if(colback) colback(null, true);
})
.then(function () {
    // Всегда выполняется
});
