import {IVariables} from "store/models/index.d";

export interface Props {
    isMobile: boolean,
    language: string,
    variables: IVariables,
    history: any,
    abort: any,
    params: {
        id: string
    }
}

export interface RelatedArticles {
    id: number,
    id_journal: number,
    title: string,
    is_index: boolean;
}

export interface Article {
    id: number,
    journalId: number,
    journalTitle: string,
    journalPart: string,
    title: string,
    annotation: string,
    authorsList: any[],
    DOI: string,
    publDate: string,
    ISSNPrint: string,
    ISSNOnline: string,
    journalNumber: string,
    keywords: any[],
    URL: string,
    PDFpath: string,
    journalIsOld: boolean,
    sourceLiterature: string[],
    is_index: boolean,
}

export type State = {
    loading: boolean,
    article: Article,
    articles: RelatedArticles[],
    articleHeight: number,
    metadata: any[],
    links: any[],
    citation: string
}

export interface IReactResizeDetector {
    width: number,
    height: number
}

export const defState = {
    loading: true,
    article: {
        id: 0,
        journalId: 0,
        journalTitle: "",
        journalPart: "",
        title: "",
        annotation: "",
        authorsList: [],
        DOI: "",
        publDate: "",
        ISSNPrint: "",
        ISSNOnline: "",
        journalNumber: "",
        keywords: [],
        URL: "",
        PDFpath: "",
        journalIsOld: false,
        sourceLiterature: [],
        is_index: false
    },
    articles: [],
    metadata: [],
    links: [],
    articleHeight: 50,
    citation: ""
}
