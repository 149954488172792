import React, {FC, useEffect, useState} from "react";
import {UpOutlined, FilePdfOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {Props, State, initialState, IReactResizeDetector} from "./index.d";
import ReactResizeDetector from "react-resize-detector";
import {BackTop, Col, Row, Spin, Tooltip, Typography} from "antd";
import {goToTop, configureAnchors} from "react-scrollable-anchor";
import RenderRow from "./renderRow";
import {Helmet} from "react-helmet";
import Axios from "axios";

const {Title, Text} = Typography;
configureAnchors({offset: 0, scrollDuration: 0});

const ProjectIndexScreen: FC<Props> = ({params, language, isMobile, variables}) => {
    const [state, set_state] = useState<State>(initialState);
    const {loading, article, articles, articleHeight, metadata, links, citation} = state;

    useEffect(() => {
        load_data()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, language])

    return (
        <div className="search-result-screen render-article">
            <Helmet>
                <title>{getTitle()}</title>
                {links.map((val: any, idx) => (
                    <link {...val} key={idx}/>
                ))}
                {metadata.map((val: any, idx) => (
                    <meta {...val} key={idx} />
                ))}
            </Helmet>
            <RenderRow
                isMobile={isMobile} loading={loading} articleHeight={articleHeight}
                article={article}
                articles={articles}>
                <ReactResizeDetector handleHeight>
                    {({width, height}: IReactResizeDetector) => {
                        if (articleHeight !== height) set_article_height(height)
                        return (
                            <>
                                {!article.title?(
                                    <Spin spinning={loading}><div className="empty-box" /></Spin>
                                ):(
                                    <Spin spinning={loading}>
                                        <Tooltip title={variables.hoverTextOnRADSIndex}>
                                            <div
                                                onClick={() => window.open("https://index.rads-doi.org/")}
                                                style={{
                                                    position: "absolute",
                                                    right: isMobile? 10: 20,
                                                    top: isMobile? 15: 25,
                                                    cursor: "pointer"
                                                }}
                                                className="article-rads-index">
                                                RADS index
                                            </div>
                                        </Tooltip>
                                        <div className="section-box">
                                            <Title
                                                level={isMobile?3:1}
                                                className="title-color"
                                                style={{
                                                    paddingRight: isMobile? 115: 120
                                                }}
                                                >
                                                {article.journalTitle}
                                            </Title>
                                            <Title level={isMobile?4:3} className={`${(isMobile?"mt-0 Hyphenation":"pb-20")}`}>{article.title}</Title>
                                            <div>
                                                <b className="mb-5">{variables.authors}:</b>
                                                <p>
                                                    {article.authorsList.map((val: any, idx: number) => {
                                                        if (val.orsd) {
                                                            return (
                                                                <a
                                                                    key={idx}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    href={"https://orcid.org/" + val.orsd}>
                                                                    <Text
                                                                        className="dib mb-5 keyword">
                                                                        <span className="button-orsd" />
                                                                        {val.name}
                                                                    </Text>
                                                                </a>
                                                            )
                                                        } else {
                                                            return (
                                                                <Text
                                                                    key={idx}
                                                                    className="dib mb-5 keyword">
                                                                    {val.name}
                                                                </Text>
                                                            )
                                                        }
                                                    })}
                                                </p>
                                            </div>
                                            <p>
                                                <b className="pr-10">{variables.DOI}:</b>
                                                {!article.journalIsOld?(
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={"https://doi.org/" + article.DOI}>
                                                        <Text underline className="c-pointer">{article.DOI}</Text>
                                                    </a>
                                                ):(
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={"https://search.datacite.org/works?query=" + article.title}>
                                                        <Text underline className="c-pointer">{variables.checkLink}</Text>
                                                    </a>
                                                )}
                                            </p>
                                            <p>
                                                <b className="pr-10">{variables.URL}:</b>
                                                <a
                                                    className="Hyphenation"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={article.URL}>
                                                    <Text underline className="c-pointer">{article.URL}</Text>
                                                </a>
                                            </p>
                                            {article.publDate?(
                                                <p>
                                                    <b className="pr-10">{variables.publicationDate}:</b>
                                                    <span>
                                                        {article.publDate}
                                                    </span>
                                                </p>
                                            ):null}
                                            {article.ISSNPrint?(
                                                <>
                                                    <b className="mb-5">{variables.ISSNPrint}:</b>
                                                    <p>
                                                        {article.ISSNPrint}
                                                    </p>
                                                </>
                                            ):null}
                                            {article.ISSNOnline?(
                                                <>
                                                    <b className="mb-5">{variables.ISSNOnline}:</b>
                                                    <p>
                                                        {article.ISSNOnline}
                                                    </p>
                                                </>
                                            ):null}
                                            {article.journalNumber?(
                                                <>
                                                    <b className="mb-5">{variables.releaseNumber}:</b>
                                                    <p>
                                                        {article.journalNumber}
                                                    </p>
                                                </>
                                            ):null}
                                            <div>
                                                <b className="mb-5">{variables.keywords}:</b>
                                                <p>
                                                    {article.keywords.map((val: string, idx: number) => {
                                                        return (
                                                            <Text
                                                                key={idx}
                                                                className="dib mb-5 keyword">
                                                                {val}
                                                            </Text>
                                                        )
                                                    })}
                                                </p>
                                            </div>
                                            {citation?(
                                                <div>
                                                    <b className="mb-5">{variables.quote}:</b>
                                                    <p className="box-citation Hyphenation">{citation}</p>
                                                </div>
                                            ):null}
                                            <div>
                                                <b className="mb-5">{variables.annotation}:</b>
                                                <p>{article.annotation}</p>
                                            </div>
                                            {(Array.isArray(article.sourceLiterature) && article.sourceLiterature.length > 0)?(
                                                <div style={{marginBottom: "1rem"}} className="source-literature">
                                                    <b className="mb-5">{variables.listOfReferences}:</b>
                                                    <ul>
                                                        {article.sourceLiterature.map((item: string, idx: number) => {
                                                            return (
                                                                <li key={idx}>{item}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            ):null}
                                            {article.PDFpath?(
                                                <Row>
                                                        <Col span={isMobile?12:6} className="block block-link-shadow">
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="block-content"
                                                                href={article.PDFpath}>
                                                                <span className="icon">
                                                                    <FilePdfOutlined />
                                                                    <p>{variables.PdfFullArticle}</p>
                                                                </span>
                                                            </a>
                                                        </Col>
                                                </Row>
                                            ):null}
                                        </div>
                                    </Spin>
                                )}
                            </>
                        )
                    }}
                </ReactResizeDetector>
            </RenderRow>
            {!isMobile?(
                <BackTop>
                    <div className="backToTop">
                        <UpOutlined />
                    </div>
                </BackTop>
            ):null}
        </div>
    )

    async function load_data() {
        set_state({...state, loading: true})

        try {
            const response = await Axios.all([
                Axios.post(`/api/metadata/article/${params.id}/index`),
                Axios.post("/api/project_index", {language, ...params})
            ]);

            if (response[0].status === 200 && response[1].status === 200) {
                goToTop();

                set_state({
                    ...state,
                    ...response[0].data,
                    ...response[1].data,
                    loading: false
                })
            } else {
                set_state({
                    ...state,
                    loading: false,
                })
            }
        } catch (error) {
            set_state({
                ...state,
                loading: false,
            })
        }
    }

    function set_article_height(height: number) {
        if (height) {
            set_state({
                ...state,
                articleHeight: height
            })
        }
    }

    function getTitle() {
        let title: string = "";
        if (article?.title) title = article.title;
        if (article?.authorsList && Array.isArray(article.authorsList)) {
            if (title) title += " - ";
            article.authorsList.forEach((val: any, idx: number) => {
                title += val.name;
                if (idx !== article.authorsList.length -1) title += ", ";
            })
        }
        if (title) title += " - репозиторий РАЦС";
        else title = "Репозиторий РАЦС";

        return title;
    }
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        language: store.state.appLanguage,
        variables: store.state.variables,
    };
}

export default connect(mapState)(ProjectIndexScreen);
