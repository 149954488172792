import {IVariables, IOptions} from "store/models/index.d";
import {IDataToSearch} from "components/screens/searchResultScreen/index.d";

export interface SearchFormProps {
    isMobile: boolean,
    variables: IVariables,
    options: IOptions,
    handleSubmit: any,
    sending: boolean,
    dataToSearch?: IDataToSearch;
    language: string;
}

export interface FilterRowProps {
    isMobile: boolean,
    form: any,
    variables: IVariables,
    options: IOptions,
    sending: boolean,
    language: string;
}

export interface IRow {
    [index: string]: any,
    logicalOperations: string,
    type: string,
    value: any,
}

export interface IType {
    name: string,
    value: string,
}

export type FilterRowState = {
    rows: IRow[]
}

export interface RenderRowProps {
    rows: IRow[],
    sending: boolean,
    variables: any,
    handleChangeRow: any,
    handleRemoveRow: any,
    handleAddRow: any,
    getTypes: any,
    getLogicalOperations: any,
    renderComponentByType: any,
}

export const defaultRow = [
    {
        exactMatch: false,
        logicalOperations: "and",
        type: "allFields",
        value: null
    }
]

export const initialFormValues = {
    string: "",
    advancedSearch: false,
    rows: defaultRow,
}
