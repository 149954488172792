import React, {Component} from "react";
import {connect} from 'react-redux';
import {Row, Col, Spin, Typography, Modal} from "antd";
import {Helmet} from "react-helmet";

import {post} from "store/senders";
import Article from "components/partial/article";
import {Props, State, IArticle} from "./index.d";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import moment from "moment";

const {Title, Text} = Typography;

type HttpRedirect = {
    href: string;
    message: string;
    need: boolean;
    seconds: number;
}

const defHttpRedirect: HttpRedirect = {
    href: "",
    message: "",
    need: false,
    seconds: 10
}

class ProjectScreen extends Component<Props, State & {
    httpRedirect: HttpRedirect;
    modalVisible: boolean;
    messageDataLoaded: string | null;
}> {

    readonly state = {
        loading: true,
        articles: [],
        journal: {
            title: "",
            releaseInfo: "",
            publisher: "",
            DOIfullJourn: "",
            DOIfullJournURL: "",
            datap: "",
            issn: "",
            eissn: "",
            number1: "",
            number2: "",
            resourceType: 0,
        },
        httpRedirect: defHttpRedirect,
        modalVisible: false,
        messageDataLoaded: null
    }

    componentDidMount = () => this.loadData();

    componentDidUpdate = (prevProps: Props) => {
        const self = this;
        if (self.props.language !== prevProps.language) {
            self.setState({loading: true}, () => {
                self.loadData();
            })
        }
    }

    loadData = () => {
        const self = this,
            {params, language} = this.props;

        post('/api/project', {language, ...params}, (response: any, error: boolean) => {
            if (!error && response?.articles && response?.journal) {
                const articles: IArticle[] = response.articles,
                journal: any = response.journal,
                httpRedirect: HttpRedirect = response.httpRedirect? response.httpRedirect: defHttpRedirect,
                messageDataLoaded: string | null = response.messageDataLoaded? response.messageDataLoaded: null,
                modalVisible: boolean = httpRedirect.need && httpRedirect.href? true: false;

                self.setState({journal, articles, loading: false, httpRedirect, modalVisible, messageDataLoaded})
            }
        })
    }

    getTitle = () => {
        const {journal} = this.state;

        let title: string = "";
        if (journal?.title) title = journal.title;
        if (journal?.releaseInfo) title += " " + journal.releaseInfo;

        if (journal?.publisher) {
            if (title) title += " -";
            title += " " + journal.publisher;
        }

        if (title) title += " - репозиторий РАЦС";
        else title = "Репозиторий РАЦС";

        return title;
    }

    render() {
        const self = this,
            {loading, articles, journal, httpRedirect, modalVisible, messageDataLoaded} = self.state,
            {isMobile, variables} = self.props;

        return (
            <div className="search-result-screen">
                <Helmet>
                    <title>{self.getTitle()}</title>
                </Helmet>
                <Row justify="center">
                    <Col span={isMobile?24:18} className={isMobile?"mt-15":""}>
                        <Spin spinning={loading}>
                            <Row>
                                {journal.resourceType !== 30 && (
                                    <>
                                        <Col span={24}>
                                            <div className={`section-box ${(isMobile?"mb-15":"mb-30")}`}>
                                                <Title level={isMobile?2:1} className="m-0 mb-10 title-color">{journal.title}</Title>
                                                {/* <Title level={3} className="m-0">{journal.releaseInfo}</Title> */}
                                                {journal.DOIfullJourn && (
                                                    <p>
                                                        <b className="pr-10">{variables.DOI}:</b>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={"https://doi.org/" + journal.DOIfullJourn}>
                                                            <Text underline className="c-pointer">{journal.DOIfullJourn}</Text>
                                                        </a>
                                                    </p>
                                                )}
                                                {journal.DOIfullJournURL && (
                                                    <p>
                                                        <b className="pr-10">{variables.URL}:</b>
                                                        <a
                                                            className="Hyphenation"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={journal.DOIfullJournURL}>
                                                            <Text underline className="c-pointer">{journal.DOIfullJournURL}</Text>
                                                        </a>
                                                    </p>
                                                )}
                                                {journal.datap?(
                                                    <p>
                                                        <b className="pr-10">{variables.publicationDate}:</b>
                                                        <span>
                                                            {moment(journal.datap).format("DD.MM.YYYY")}
                                                        </span>
                                                    </p>
                                                ):null}
                                                {journal.issn?(
                                                    <>
                                                        <b className="mb-5">{variables.ISSNPrint}:</b>
                                                        <p>
                                                            {journal.issn}
                                                        </p>
                                                    </>
                                                ):null}
                                                {journal.eissn?(
                                                    <>
                                                        <b className="mb-5">{variables.ISSNOnline}:</b>
                                                        <p>
                                                            {journal.eissn}
                                                        </p>
                                                    </>
                                                ):null}
                                                {journal.number1?(
                                                    <>
                                                        <b className="mb-5">{variables.releaseNumber}:</b>
                                                        <p>
                                                            {journal.number1} {journal.number2? `(${journal.number2})`: null}
                                                        </p>
                                                    </>
                                                ):null}
                                            </div>
                                        </Col>
                                    </>
                                )}
                            </Row>
                            <Row>
                                {messageDataLoaded === null? (
                                    <>
                                        {articles.length > 0 && (
                                            <Col span={24}>
                                                <div className="articles-section">
                                                    <div className={isMobile?"":"section-box"}>
                                                        {articles.map((article: IArticle, idx: number) => {
                                                            return (
                                                                <Article
                                                                    variables={variables}
                                                                    article={article}
                                                                    key={idx}/>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                    </>
                                ): (
                                    <Col span={24}>
                                        <div className="articles-section">
                                            <div className="section-box">
                                                <div style={{
                                                    color: "rgba(0, 0, 0, 0.85)",
                                                    fontWeight: 600,
                                                    fontSize: 20,
                                                    textAlign: "center"
                                                }}>
                                                    {messageDataLoaded}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Spin>
                    </Col>
                </Row>
                {httpRedirect.need && httpRedirect.href && (
                    <Modal
                        className="httpRedirect"
                        visible={modalVisible}
                        onCancel={() => {
                            this.setState({modalVisible: false})
                        }}>
                        <div className="timer">
                            <CountdownCircleTimer
                                isPlaying
                                duration={httpRedirect.seconds}
                                colors="#1890ff"
                                size={130}
                                strokeWidth={9}
                                onComplete={() => {
                                    if (modalVisible) window.location.href = httpRedirect.href;
                                }}>
                                {({
                                    remainingTime
                                }: any) => {
                                    return <div className="value">{remainingTime}</div>;
                                }}
                            </CountdownCircleTimer>
                        </div>
                        <div className="description">
                            {httpRedirect.message} <a href={httpRedirect.href}>{httpRedirect.href}</a>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        language: store.state.appLanguage,
        variables: store.state.variables,
    };
}

export default connect(mapState)(ProjectScreen);
