const ls = require('local-storage');

const _storeData = (key, dataSource) => {
    let name = '@SearchRadsDoi:' + key;
    return new Promise((resolve) => {
        try {
            ls.set(name, dataSource);
            resolve(true);
        } catch (error) {
            resolve(false);
        }
    });
};

const _retrieveData = (key) => {
    let name = '@SearchRadsDoi:' + key;
    return new Promise((resolve) => {
        try {
            const value = ls(name);
            if (value !== null) {
                resolve({jsonstr: value, status: true})
            } else {
                resolve({jsonstr: null, status: true})
            }
        } catch (error) {
            resolve({jsonstr: null, status: true})
        }
    });
};

const DB = {
    _storeData: _storeData,
    _retrieveData: _retrieveData,
}

export default DB;
