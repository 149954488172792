import React, {Suspense} from "react";
import {connect} from "react-redux";
import {Props} from "./index.d";

const DesktopArticle = React.lazy(() => import("./desktop")),
MobileArticle = React.lazy(() => import("./mobile"));

const ArticleConnect: React.FC<Props> = (props) => {
    if (props.isMobile) return (
        <Suspense fallback="">
            <MobileArticle {...props} />
        </Suspense>
    )
    else return (
        <Suspense fallback="">
            <DesktopArticle {...props} />
        </Suspense>
    )
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
    };
};
export default connect(mapState)(ArticleConnect);
