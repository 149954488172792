import React from "react";
import {Spin} from "antd";

const Loading = () => {
    return (
        <div className="page-loading">
            <Spin size="large" />
        </div>
    )
}
export default Loading;
