import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Helmet} from "react-helmet";

import {post} from "store/senders";
import {SearchScreenProps, SearchScreenState} from "./index.d";
import logo from 'styles/images/logo.svg'
import SearchForm from './extra'

const queryString = require('query-string');

class SearchScreen extends Component<SearchScreenProps, SearchScreenState> {

    readonly state = {
        sending: false
    }

    handleSubmit = (values: any): void => {
        const self = this,
            {appLanguage, history} = self.props;

        let params = Object.assign({}, values);
        if (params.advancedSearch) params.rows = JSON.stringify(params.rows);
        else delete params.rows;

        self.setState({sending: true})
        post('/api/search/check', {language: appLanguage, values}, (response: any, error: boolean) => {
            self.setState({sending: false})
            if (!error) {
                let url = '/search/' + queryString.stringify(params)
                history.push(url, {alreadyVerified: true})
            }
        })
    }

    render() {
        const self = this,
            {sending} = self.state,
            {isMobile, variables, options, appLanguage} = self.props;

        return (
            <>
                <Helmet>
                    <title>Репозиторий РАЦС</title>
                </Helmet>
                <div className="search-screen animate__animated animate__fadeInUp">
                    <div className="logo-box">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="search-form-box">
                        <SearchForm
                            isMobile={isMobile}
                            sending={sending}
                            variables={variables}
                            options={options}
                            handleSubmit={self.handleSubmit}
                            language={appLanguage}
                        />
                    </div>
                </div>
            </>
        )
    }
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        appLanguage: store.state.appLanguage,
        variables: store.state.variables,
        options: store.state.options,
    };
}

export default connect(mapState)(SearchScreen);
