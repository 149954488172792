import React, {Component, Suspense} from "react";
import {Col, Select, Input, DatePicker} from 'antd';
import moment from "moment";
import "moment/locale/ru";

import {IOption} from "store/models/index.d";
import {FilterRowProps, FilterRowState, IRow, IType, defaultRow} from "../index.d";
import DesktopRow from "./desktop";
import MobileRow from "./mobile";

const {Option} = Select;

class FilterRow extends Component<FilterRowProps, FilterRowState> {

    readonly state = {
        rows: defaultRow
    }

    componentDidMount = () => {
        const {form} = this.props,
        rows = form.getFieldsValue()?.rows?form.getFieldsValue().rows:[];

        moment.locale('ru');
        this.setState({rows})
    }

    handleChangeRow = (idx: number, name: string, value: any) => {
        const clone = JSON.stringify(this.state.rows);
        let rows: IRow[] = JSON.parse(clone);
        const oldRowType = rows[idx].type;
        const ifMatchedThenDelete = ["publicationDate", "category", "typeArticleInRSCI"];

        rows[idx][name] = value;
        if (oldRowType !== rows[idx].type) {
            if (ifMatchedThenDelete.includes(oldRowType) || ifMatchedThenDelete.includes(rows[idx].type)) {
                rows[idx].value = null;
            }
        }

        this.saveRowsToForm(rows)
    }

    handleAddRow = (idx: number) => {
        let rows: IRow[] = [];
        this.state.rows.forEach((row: IRow, i: number) => {
            if (i !== idx) rows.push(row);
            else {
                rows.push(row);
                rows.push(defaultRow[0]);
            }
        });
        this.saveRowsToForm(rows)
    }

    handleRemoveRow = (idx: number) => {
        let rows: IRow[] = [];
        this.state.rows.forEach((row: IRow, i: number) => {
            if (i !== idx) rows.push(row);
        });
        this.saveRowsToForm(rows)
    }

    saveRowsToForm = (rows: IRow[]) => {
        this.setState({rows})
        if (this.props?.form?.setFieldsValue) {
            this.props.form.setFieldsValue({rows})
        }
    }

    getTypes = (): IType[] => {
        const {variables} = this.props;
        return [
            // Все поля
            {value: "allFields", name: variables.allFields},
            // Название // Заголовок объекта
            {value: "denomination", name: variables.denomination},
            // Фамилия автора
            {value: "author", name: variables.authorSurname},
            // Ключевые слова
            {value: "keywords", name: variables.keyword},
            // Аннотация
            {value: "annotation", name: variables.annotation},
            // Текст объекта // любое из полей с основным текстом
            // {value: "objectText", name: variables.objectText},
            // Место работы / учебы автора
            {value: "affiliation", name: variables.affiliation},
            // Категория (Беруться категории ВАК 1 уровня)
            {value: "category", name: variables.category},
            // DOI
            {value: "DOI", name: variables.DOI},
            // Дата публикации
            {value: "publicationDate", name: variables.publicationDate},
            // ISSN
            {value: "ISSN", name: "ISSN"},
            // Финансирование
            {value: "financing", name: variables.financing},
            // ORCID
            {value: "ORCID", name: variables.ORCID},
            // Тип статьи // берется из разметки ринц
            {value: "typeArticleInRSCI", name: variables.articleType},
            // Cписок литературы // берется из разметки ринц / crossref
            {value: "sourceLiterature", name: variables.sourceLiterature},
            // Издатель/Регистрант
            {value: "registrant", name: variables.publisherOrRegistrant},
            // Фамилия рецензента // берется из разметки ринц
            // {value: "reviewerLastName", name: variables.reviewerLastName},
        ]
    }

    getLogicalOperations = (): IType[] => {
        const {variables} = this.props;
        return [
            {value: "and", name: variables.and},
            {value: "or", name: variables.or},
            {value: "besides", name: variables.besides},
        ]
    }

    renderComponentByType = (idx: number, row: IRow) => {
        const self = this,
        {isMobile, options, sending, language} = self.props;

        switch (row.type) {
            case "publicationDate":
                let rangePickerStart = null,
                    rangePickerEnd = null;

                if (row.value?.start) {
                    rangePickerStart = moment(row.value.start, 'DD.MM.YYYY');
                }
                if (row.value?.end) {
                    rangePickerEnd = moment(row.value.end, 'DD.MM.YYYY');
                }
                return (
                    <Col span={isMobile?24:10}>
                        <DatePicker.RangePicker
                            value={[rangePickerStart, rangePickerEnd]}
                            className="wp-100"
                            format="DD.MM.YYYY"
                            disabled={sending}
                            onChange={(dates, dateStrings) => self.handleChangeRow(idx, 'value', {start: dateStrings[0], end: dateStrings[1]})}
                        />
                    </Col>
                )
            case "category":
                let codeBAK: IOption = options?.codeBAK?options.codeBAK:{};
                return (
                    <Col span={isMobile?24:10}>
                        <Select
                            value={row.value}
                            className="wp-100"
                            listHeight={32 * Object.keys(codeBAK).length}
                            disabled={sending}
                            onChange={(value: string) => self.handleChangeRow(idx, 'value', value)}>
                            {Object.keys(codeBAK).map((key: string, idx: number) => {
                                let keyNumber = Number(key);
                                if (!isNaN(keyNumber) && typeof codeBAK[keyNumber] === "string") {
                                    return <Option key={idx} value={key}>{codeBAK[keyNumber]}</Option>
                                } else return null;
                            })}
                        </Select>
                    </Col>
                );
            case "typeArticleInRSCI":
                let typeArticleInRSCI: IOption = options?.typeArticleInRSCI?options.typeArticleInRSCI:{};
                return (
                    <Col span={isMobile?24:10}>
                        <Select
                            value={row.value}
                            className="wp-100"
                            disabled={sending}
                            listHeight={32 * Object.keys(typeArticleInRSCI).length}
                            onChange={(value: string) => self.handleChangeRow(idx, 'value', value)}>
                            {Object.keys(typeArticleInRSCI).map((key: string, idx: number) => {
                                if (typeof typeArticleInRSCI[key] === "string") {
                                    return <Option key={idx} value={key}>{typeArticleInRSCI[key]}</Option>
                                } else return null;
                            })}
                        </Select>
                    </Col>
                );
            case "registrant":
                return (
                    <Col span={isMobile?24:10}>
                        <Select
                            value={row.value}
                            className="wp-100"
                            disabled={sending}
                            listHeight={64}
                            onChange={(value: string) => self.handleChangeRow(idx, 'value', value)}
                            >
                            <Option value={0}>{language === "ru"? "Гефест": "Gefest"}</Option>
                            <Option value={1}>RADS index</Option>
                        </Select>
                    </Col>
                );
            default:
                return (
                    <Col span={isMobile?24:10}>
                        <Input
                            value={row.value}
                            disabled={sending}
                            onChange={({target: {value}}) => self.handleChangeRow(idx, 'value', value)}
                            className="wp-100"/>
                    </Col>
                );
        }
    }

    render() {
        const self = this,
            {rows} = self.state,
            {isMobile, sending, variables} = self.props,
            props = {
                rows,
                sending,
                variables,
                handleChangeRow: self.handleChangeRow,
                handleRemoveRow: self.handleRemoveRow,
                handleAddRow: self.handleAddRow,
                getTypes: self.getTypes,
                getLogicalOperations: self.getLogicalOperations,
                renderComponentByType: self.renderComponentByType,
            }

        if (isMobile) return (
            <Suspense fallback="">
                <MobileRow {...props}/>
            </Suspense>
        )
        return (
            <Suspense fallback="">
                <DesktopRow {...props}/>
            </Suspense>
        )
    }
}
export default FilterRow;
