import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ConfigProvider} from 'antd';

import enUS from 'antd/es/locale/en_US';
import ruRU from 'antd/lib/locale-provider/ru_RU';

import Navigations from "./components/navigations";
import Loading from "./components/partial/loading";

interface AppProps {
    isMobile: boolean,
    appLanguage?: string,
    fnInitLanguages?: any,
    fnInitHeader?: any,
}

type AppStore = {
    ready?: boolean,
    errorInReady?: boolean,
}

class App extends Component<AppProps, AppStore> {

    readonly state = {
        ready: false,
        errorInReady: false,
    };

    static defaultProps = {
        isMobile: false,
        appLanguage: 'ru',
        fnInitLanguages: () => {},
        fnInitHeader: () => {},
    }

    componentDidMount = () => {
        const self = this;
        const {fnInitLanguages, fnInitHeader} = this.props;

        fnInitLanguages(null, (noError: boolean) => {
            if (noError) {
                fnInitHeader(null, (noError: boolean) => {
                    if (noError) self.setState({ready: true})
                    else self.setState({errorInReady: true})
                });
            } else self.setState({errorInReady: true})
        });
    }

    getLocaleApp = () => {
        const {appLanguage} = this.props;
        switch (appLanguage) {
            case 'us':
                return enUS;
            default:
                return ruRU;
        }
    }

    render() {
        const self = this,
        {ready, errorInReady} = self.state,
        {isMobile} = self.props;

        return (
            <ConfigProvider locale={self.getLocaleApp()}>
                <div className={`App ${(isMobile?"mobile":"")}`}>
                    {ready?(
                        <Navigations />
                    ):errorInReady?(
                        <>
                            {"Ошибка"}
                        </>
                    ):<Loading />}
                </div>
            </ConfigProvider>
        );
    }
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        appLanguage: store.state.appLanguage,
    };
}, mapDispatch = (dispatch: any) => {
    return {
        fnInitLanguages: (data: any, callback: any) => dispatch.state.initLanguages({data, callback}),
        fnInitHeader: (data: any, callback: any) => dispatch.state.initHeader({data, callback}),
    };
}

export default connect(mapState, mapDispatch)(App);
