import React, {Component} from "react";
import {connect} from "react-redux";
import {Row, Col, Spin, Typography, BackTop, Modal} from "antd";
import {UpOutlined, FilePdfOutlined} from "@ant-design/icons";
import {goToTop, configureAnchors} from "react-scrollable-anchor"
import {Helmet} from "react-helmet";
import ReactResizeDetector from "react-resize-detector";
import {CountdownCircleTimer} from "react-countdown-circle-timer";

import {post} from "store/senders";
import {Props, State, RelatedArticles, Article, IReactResizeDetector, defState} from "./index.d";
import RenderRow from "./renderRow";

const {Title, Text} = Typography;
configureAnchors({offset: 0, scrollDuration: 0});

type HttpRedirect = {
    href: string;
    message: string;
    need: boolean;
    seconds: number;
}

const defHttpRedirect: HttpRedirect = {
    href: "",
    message: "",
    need: false,
    seconds: 10
}

class ArticleScreen extends Component<Props, State & {
    httpRedirect: HttpRedirect;
    modalVisible: boolean;
    messageDataLoaded: string | null;
}> {

    readonly state = {
        ...defState,
        httpRedirect: defHttpRedirect,
        modalVisible: false,
        messageDataLoaded: null
    };

    componentDidMount = () => {
        const self = this,
            {params, language} = this.props;

        new Promise((resolve: any) => {
            post("/api/metadata/article/" + params.id, {}, (response: any, error: boolean) => {
                self.setState({metadata: response.metadata, links: response.links}, () => {
                    resolve();
                })
            })
        }).then(() => {
            post('/api/project/article', {language, ...params}, (response: any, error: boolean) => {
                if (!error && response?.articles && response?.article) {
                    goToTop()
                    const article: Article = response.article,
                    articles: RelatedArticles[] = response.articles,
                    citation: string = response.citation,
                    httpRedirect: HttpRedirect = response.httpRedirect? response.httpRedirect: defHttpRedirect,
                    messageDataLoaded: string | null = response.messageDataLoaded? response.messageDataLoaded: null,
                    modalVisible: boolean = httpRedirect.need && httpRedirect.href? true: false;

                    self.setState({articles, article, citation, loading: false, httpRedirect, modalVisible, messageDataLoaded})
                } else if (!error) {
                    const messageDataLoaded: string | null = response.messageDataLoaded? response.messageDataLoaded: null;
                    self.setState({loading: false, messageDataLoaded})
                }
            })
        });
    }

    componentDidUpdate = (prevProps: Props, prevState: State) => {
        const self = this;

        if (self.props.params !== prevProps.params) {
            self.setState({loading: true})
            self.componentDidMount();
        }
        if (self.state.loading) {
            if (self.props.language !== prevProps.language) {
                self.setState({loading: true})
                self.componentDidMount();
            }
        }
    }

    setArticleHeight = (height: number): void => {
        if (height) {
            this.setState({articleHeight: height})
        }
    }

    getTitle = () => {
        const {article} = this.state;

        let title: string = "";
        if (article?.title) title = article.title;
        if (article?.authorsList && Array.isArray(article.authorsList)) {
            if (title) title += " - ";
            article.authorsList.forEach((val: any, idx: number) => {
                title += val.name;
                if (idx !== article.authorsList.length -1) title += ", ";
            })
        }
        if (title) title += " - репозиторий РАЦС";
        else title = "Репозиторий РАЦС";

        return title;
    }

    render() {
        const self = this,
            {loading, article, articles, articleHeight, metadata, links, citation, httpRedirect, modalVisible, messageDataLoaded} = self.state,
            {isMobile, variables} = self.props;

        return (
            <div className="search-result-screen render-article">
                <Helmet>
                    <title>{self.getTitle()}</title>
                    {links.map((val: any, idx) => (
                        <link {...val} key={idx}/>
                    ))}
                    {metadata.map((val: any, idx) => (
                        <meta {...val} key={idx} />
                    ))}
                </Helmet>
                <RenderRow
                    isMobile={isMobile} loading={loading} articleHeight={articleHeight}
                    article={article}
                    articles={articles}>
                    <ReactResizeDetector handleHeight>
                        {({width, height}: IReactResizeDetector) => {
                            if (articleHeight !== height) {
                                self.setArticleHeight(height)
                            }
                            if (messageDataLoaded !== null) {
                                return (
                                    <div className="section-box" style={{
                                        marginTop: 50
                                    }}>
                                        <div style={{
                                            color: "rgba(0, 0, 0, 0.85)",
                                            fontWeight: 600,
                                            fontSize: 20,
                                            textAlign: "center",
                                        }}>
                                            {messageDataLoaded}
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <>
                                    {!article.title?(
                                        <Spin spinning={loading}><div className="empty-box" /></Spin>
                                    ):(
                                        <Spin spinning={loading}>
                                            <div className="section-box">
                                                <Title level={isMobile?3:1} className="title-color">{article.journalTitle}</Title>
                                                <Title level={isMobile?4:3} className={`${(isMobile?"mt-0 Hyphenation":"pb-20")}`}>{article.title}</Title>
                                                <div>
                                                    <b className="mb-5">{variables.authors}:</b>
                                                    <p>
                                                        {article.authorsList.map((val: any, idx: number) => {
                                                            if (val.orsd) {
                                                                return (
                                                                    <a
                                                                        key={idx}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href={"https://orcid.org/" + val.orsd}>
                                                                        <Text
                                                                            className="dib mb-5 keyword">
                                                                            <span className="button-orsd" />
                                                                            {val.name}
                                                                        </Text>
                                                                    </a>
                                                                )
                                                            } else {
                                                                return (
                                                                    <Text
                                                                        key={idx}
                                                                        className="dib mb-5 keyword">
                                                                        {val.name}
                                                                    </Text>
                                                                )
                                                            }
                                                        })}
                                                    </p>
                                                </div>
                                                <p>
                                                    <b className="pr-10">{variables.DOI}:</b>
                                                    {!article.journalIsOld?(
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={"https://doi.org/" + article.DOI}>
                                                            <Text underline className="c-pointer">{article.DOI}</Text>
                                                        </a>
                                                    ):(
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={"https://search.datacite.org/works?query=" + article.title}>
                                                            <Text underline className="c-pointer">{variables.checkLink}</Text>
                                                        </a>
                                                    )}
                                                </p>
                                                <p>
                                                    <b className="pr-10">{variables.URL}:</b>
                                                    <a
                                                        className="Hyphenation"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={article.URL}>
                                                        <Text underline className="c-pointer">{article.URL}</Text>
                                                    </a>
                                                </p>
                                                {article.publDate?(
                                                    <p>
                                                        <b className="pr-10">{variables.publicationDate}:</b>
                                                        <span>
                                                            {article.publDate}
                                                        </span>
                                                    </p>
                                                ):null}
                                                {article.ISSNPrint?(
                                                    <>
                                                        <b className="mb-5">{variables.ISSNPrint}:</b>
                                                        <p>
                                                            {article.ISSNPrint}
                                                        </p>
                                                    </>
                                                ):null}
                                                {article.ISSNOnline?(
                                                    <>
                                                        <b className="mb-5">{variables.ISSNOnline}:</b>
                                                        <p>
                                                            {article.ISSNOnline}
                                                        </p>
                                                    </>
                                                ):null}
                                                {article.journalNumber?(
                                                    <>
                                                        <b className="mb-5">{variables.releaseNumber}:</b>
                                                        <p>
                                                            {article.journalNumber}
                                                        </p>
                                                    </>
                                                ):null}
                                                <div>
                                                    <b className="mb-5">{variables.keywords}:</b>
                                                    <p>
                                                        {article.keywords.map((val: string, idx: number) => {
                                                            return (
                                                                <Text
                                                                    key={idx}
                                                                    className="dib mb-5 keyword">
                                                                    {val}
                                                                </Text>
                                                            )
                                                        })}
                                                    </p>
                                                </div>
                                                {citation?(
                                                    <div>
                                                        <b className="mb-5">{variables.quote}:</b>
                                                        <p className="box-citation Hyphenation">{citation}</p>
                                                    </div>
                                                ):null}
                                                <div>
                                                    <b className="mb-5">{variables.annotation}:</b>
                                                    <p>{article.annotation}</p>
                                                </div>
                                                {(Array.isArray(article.sourceLiterature) && article.sourceLiterature.length > 0)?(
                                                    <div className="source-literature">
                                                        <b className="mb-5">{variables.listOfReferences}:</b>
                                                        <ul>
                                                            {article.sourceLiterature.map((item: string, idx: number) => {
                                                                return (
                                                                    <li key={idx}>{item}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                ):null}
                                                <Row>
                                                    {article.PDFpath?(
                                                        <Col span={isMobile?12:6} className="block block-link-shadow">
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="block-content"
                                                                href={article.PDFpath}>
                                                                <span className="icon">
                                                                    <FilePdfOutlined />
                                                                    <p>{variables.PdfFullArticle}</p>
                                                                </span>
                                                            </a>
                                                        </Col>
                                                    ):null}
                                                </Row>
                                            </div>
                                        </Spin>
                                    )}
                                </>
                            )
                        }}
                    </ReactResizeDetector>
                </RenderRow>
                {!isMobile?(
                    <BackTop>
                        <div className="backToTop">
                            <UpOutlined />
                        </div>
                    </BackTop>
                ):null}
                {httpRedirect.need && httpRedirect.href && (
                    <Modal
                        className="httpRedirect"
                        visible={modalVisible}
                        onCancel={() => {
                            this.setState({modalVisible: false})
                        }}>
                        <div className="timer">
                            <CountdownCircleTimer
                                isPlaying
                                duration={httpRedirect.seconds}
                                colors="#1890ff"
                                size={130}
                                strokeWidth={9}
                                onComplete={() => {
                                    if (modalVisible) window.location.href = httpRedirect.href;
                                }}>
                                {({
                                    remainingTime
                                }: any) => {
                                    return <div className="value">{remainingTime}</div>;
                                }}
                            </CountdownCircleTimer>
                        </div>
                        <div className="description">
                            {httpRedirect.message} <a href={httpRedirect.href}>{httpRedirect.href}</a>
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        language: store.state.appLanguage,
        variables: store.state.variables,
    };
}

export default connect(mapState)(ArticleScreen);
