import {notification} from "antd";
const _ = require('lodash');

export const parseError = (dataSource: any) => {
    let content = "Что-то пошло не так. Попробуйте еще раз";

    if (!_.isUndefined(dataSource.errors)) {
        if (_.isObject(dataSource.errors)) {
            content = "";
            Object.keys(dataSource.errors).forEach((key: any) => {
                content += dataSource.errors[key][0].trim() + "\n\n";
            });
        } else if (_.isString(dataSource.errors)) {
            content = dataSource.errors;
        }
    } else if (!_.isUndefined(dataSource.danger) && _.isString(dataSource.danger)) {
        content = dataSource.danger;
    } else if (!_.isUndefined(dataSource.danger) && _.isArray(dataSource.danger)) {
        content = "";
        dataSource.danger.forEach((val: any, idx: number) => {
            content += (idx+1) + ") " + val + "\n";
        });
    } else if (!_.isUndefined(dataSource.message) && _.isString(dataSource.message)) {
        content = dataSource.message;
    }

    if (content) {
        notification.error({
            message: content
        })
    }
}
