import UpOutlined from "@ant-design/icons/lib/icons/UpOutlined";
import {BackTop, Col, Row, Spin} from "antd";
import React, {useState} from "react";
import {connect} from "react-redux";
import {get} from "store/senders";
import Masonry from 'react-masonry-css';
import {Input} from "antd";
import { Helmet } from "react-helmet";
const {Search} = Input;

type Props = {
    isMobile: boolean;
    language: string;
    variables: any;
}

const BookScreen: React.FC<Props> = ({isMobile, language, variables}) => {
    const [journals, setJournals] = useState<any>({}),
    [loading, setLoading] = useState<boolean>(true);

    React.useLayoutEffect(() => {
        get('/api/books', {language}, (response: any, error: boolean) => {
            setLoading(false)
            if (!error) setJournals(response)
        })
    }, []);
    const handleSearch = (search: string) =>{
        setLoading(true)
        get(`/api/books?search=${search}`, {language}, (response: any, error: boolean) => {
            setLoading(false)
            if (!error) setJournals(response)
        })
    }

    return (
        <>
        <Helmet>
            <title>{variables.books} - Репозиторий РАЦС</title>
        </Helmet>
        <div className="search-result-screen">
            <Row justify="center">
                <Col span={isMobile? 24: 18} className={isMobile?"mt-15":""}>
                    <Spin spinning={loading}>
                        <div className="journals-screen">
                            <div className="search">
                                <Search
                                    className="journal-search"
                                    style={isMobile? {width: "100%"}: undefined}
                                    onSearch={(value: string) => handleSearch(value)}
                                    enterButton={true}
                                    placeholder={variables.searchByBooks}
                                    size="large"/>
                            </div>
                            <div className="section-box mb-30">
                                <h1 className="ant-typography m-0 title-color">{variables.books}</h1>
                            </div>
                            <Masonry
                                breakpointCols={!isMobile? 3: 1}
                                className="masonry-grid"
                                columnClassName="masonry-grid_column">
                                {Object.keys(journals).map((key, idx) => {
                                    if (!key) return <></>;
                                    return (
                                        <div key={idx} className="letter-section">
                                            <div>
                                                <div className="letter">{key}</div>
                                                <div className="journals-by-letter">
                                                    {journals[key].map((j: any, i: number) => {
                                                        return (
                                                            <div key={`${idx}-${i}`} className="journal-row">
                                                                <a href={`/project/${j.id}`} title={j.title}>
                                                                    {j.title}
                                                                </a>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Masonry>
                        </div>
                    </Spin>
                </Col>
            </Row>
            {!isMobile?(
                <BackTop>
                    <div className="backToTop">
                        <UpOutlined />
                    </div>
                </BackTop>
            ):null}
        </div>
        </>
    )
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        language: store.state.appLanguage,
        variables: store.state.variables,
    };
}

export default connect(mapState)(BookScreen);