import React, {Component, Suspense} from "react";
import {connect} from 'react-redux';
import {Row, Col, Spin, BackTop, Drawer} from 'antd';
import {UpOutlined} from "@ant-design/icons";
import {Helmet} from "react-helmet";

import {post} from "store/senders";
import {defaultRow} from "components/screens/searchScreen/extra/index.d";
import {IAFilter, IValues, IValuesData} from "./extra/additionalFilter";
import Articles from "./extra/articles";
import {IArticles} from "./extra/articles/index.d";
import SearchForm from "../searchScreen/extra"
import {handleSearch} from "../extraFn"

import {
    Props, IDataToSearch, ICancelTokens, IFoundArticle, ILoadding, State,
    defState
} from "./index.d";

const queryString = require('query-string'),
AdditionalFilter = React.lazy(() => import("./extra/additionalFilter"));

class SearchResultScreen extends Component<Props, State> {

    readonly state = defState

    setCancelTokens = (method: string, source: any): void => {
        let cancelTokens: ICancelTokens = Object.assign({}, this.state.cancelTokens);

        if (cancelTokens[method]) {
            cancelTokens[method].forEach((source: any) => {
                if (source?.cancel) source.cancel()
            })
        }
        cancelTokens[method] = [];
        cancelTokens[method].push(source);

        this.setState({cancelTokens})
    }

    componentDidMount = () => {
        this.setUrlParams();
    }

    componentDidUpdate = (prevProps: Props, prevState: State) => {
        const self = this;

        if (self.props.params !== prevProps.params) {
            self.setUrlParams();
        }
        let loading: ILoadding = {articles: false, filter: false}
        if (self.state.documentReady) {
            if (self.props.appLanguage !== prevProps.appLanguage) {
                loading.filter = true;
                loading.articles = true;
            } else if (prevState.documentReady !== self.state.documentReady) {
                loading.filter = true;
                loading.articles = true;
            } else if (prevState.dataToSearch !== self.state.dataToSearch) {
                loading.articles = true;
                loading.filter = true;
            }

            if (loading.articles || loading.filter) {
                self.setState({loading}, () => {
                    self.getSearchData();
                })
            }
        }
    }

    componentWillUnmount = () => {
        const cancelTokens: ICancelTokens = this.state.cancelTokens;

        Object.keys(cancelTokens).forEach((key: string) => {
            let sources: any[] = cancelTokens[key];
            sources.forEach((source: any) => {
                if (source?.cancel) source.cancel()
            })
        })
    }

    setUrlParams = () => {
        const self = this,
            {params, history, abort, appLanguage} = self.props;

        let alreadyVerified = false;
        if (history?.location?.state?.alreadyVerified) alreadyVerified = true;

        if (params) {
            try {
                let qParams = queryString.parse(params.data, {parseBooleans: true});
                if (qParams.rows) qParams.rows = JSON.parse(qParams.rows);
                else qParams.rows = defaultRow;

                if (qParams.additionalFilter) {
                    qParams.additionalFilter = queryString.parse(qParams.additionalFilter, {arrayFormat: 'comma'})
                    let aFilter: any = {};
                    Object.keys(qParams.additionalFilter).forEach((key: string) => {
                        if (Array.isArray(qParams.additionalFilter[key])) aFilter[key] = qParams.additionalFilter[key];
                        else {
                            aFilter[key] = [qParams.additionalFilter[key]]
                        }
                    });
                    qParams.additionalFilter = aFilter;
                };

                let dataToSearch: IDataToSearch = qParams;

                self.setState({dataToSearch}, () => {
                    if (!alreadyVerified) {
                        let checkSource = post('/api/search/check', {language: appLanguage, values: dataToSearch}, (response: any, error: boolean) => {
                            if (!error) self.setState({documentReady: true})
                        })
                        self.setCancelTokens('checkSource', checkSource)
                    } else self.setState({documentReady: true})
                });
            } catch (e) {
                abort(404);
            }
        }
    }

    getSearchData = () => {
        const self = this,
            {dataToSearch} = self.state,
            {appLanguage} = self.props;
        let loading: ILoadding = self.state.loading

        let data = {language: appLanguage, values: dataToSearch};
        post('/api/search/applay', data, (response: any, error: boolean) => {
            if (!error) {
                if (response?.articles) {
                    const foundArticles: IFoundArticle[] = response.articles;
                    self.setState({foundArticles});
                    new Promise((resolve: any) => {
                        if (!loading.articles) resolve();
                        else {
                            let source = post('/api/search/getarticles', {...data, foundArticles}, (response: any, error: boolean) => {
                                if (!error) {
                                    if (response?.articles) {
                                        const articlesData: IArticles = {...response};
                                        loading.articles = false;

                                        self.setState({
                                            articlesData,
                                            loading
                                        }, () => {
                                            resolve();
                                        });
                                    }
                                }
                            })
                            self.setCancelTokens('articlesSource', source)
                        }
                    }).then(() => {
                        if (loading.filter) {
                            let source = post('/api/search/getdata', {...data, foundArticles}, (response: any, error: boolean) => {
                                if (!error) {
                                    if (response?.additionalFilter) {
                                        const additionalFilter: IAFilter[] = response.additionalFilter;
                                        loading.filter = false;

                                        self.setState({
                                            additionalFilter,
                                            loading
                                        });
                                    }
                                }
                            })
                            self.setCancelTokens('additionalFilterSource', source)
                        }
                    })
                }
            }
        })
    }

    getParamsForUrl = () => {
        const {dataToSearch} = this.state;

        let clone = JSON.stringify(dataToSearch),
            params: any = JSON.parse(clone);

        if (params.advancedSearch) params.rows = JSON.stringify(params.rows);
        else delete params.rows;

        if (params.page) delete params.page;

        return params;
    }

    handleAdditionalFilter = (values: IValues) => {
        const self = this,
            {history} = self.props;

        let additionalFilter: any = {};
        Object.keys(values).forEach((key: string) => {
            let val: IValuesData = values[key]
            additionalFilter[key] = val.value;
        });
        if (Object.keys(additionalFilter).length) {
            let qAdditionalFilter = queryString.stringify(additionalFilter, {arrayFormat: 'comma'});

            let params: any = self.getParamsForUrl();
            params.additionalFilter = qAdditionalFilter;

            let url = '/search/' + queryString.stringify(params)
            history.push(url);
        }
    }

    handlePagination = (page: number): void => {
        const self = this,
            {history} = self.props;

            let params: any = self.getParamsForUrl();
            if (params.additionalFilter) params.additionalFilter = queryString.stringify(params.additionalFilter, {arrayFormat: 'comma'});
            params.page = page;
            let url = '/search/' + queryString.stringify(params)
            history.push(url);
    }

    handleSearch = (values: any): void => {
        const self = this,
            {history, appLanguage} = self.props;

        self.componentWillUnmount();
        handleSearch({values, history, language: appLanguage}, () => {})
    }

    handleVisibleAdditionalFilter = (value: boolean) => this.setState({visibleAdditionalFilter: value})

    render() {
        if (!this.state?.documentReady) return null;

        const self = this,
            {isMobile, variables, options, appLanguage} = self.props,
            {additionalFilter, visibleAdditionalFilter, loading, dataToSearch, articlesData} = self.state,
            searchFormProps = {
                isMobile, dataToSearch, variables, options,
                sending: false,
                handleSubmit: self.handleSearch
            }

        return (
            <>
                <Helmet>
                    <title>Поиск</title>
                </Helmet>
                {isMobile?(
                    <div className="pr-20 pt-30 pl-20">
                        <SearchForm {...searchFormProps} language={appLanguage}/>
                    </div>
                ):(
                    <div className="search-result-screen-form">
                        <Row justify="space-around" align="middle">
                            <Col span={14}>
                                <SearchForm {...searchFormProps} language={appLanguage}/>
                            </Col>
                        </Row>
                    </div>
                )}
                <div className="search-result-screen">
                    <Row>
                        <Col span={isMobile?24:18} className={isMobile?"":"pr-10"}>
                            <Spin spinning={loading.articles}>
                                <Articles
                                    searchData={dataToSearch}
                                    variables={variables}
                                    currentPage={dataToSearch.page}
                                    handlePagination={self.handlePagination}
                                    handleFilter={() => self.handleVisibleAdditionalFilter(true)}
                                    {...articlesData}/>
                            </Spin>
                        </Col>
                        {!isMobile?(
                            <Suspense fallback="">
                                <AdditionalFilter
                                    variables={variables}
                                    spinning={loading.filter || loading.articles}
                                    handle={self.handleAdditionalFilter}
                                    dataToSearch={dataToSearch}
                                    dataSource={additionalFilter}/>
                            </Suspense>
                        ):(
                            <Drawer
                                className="drawer-mobile-filter"
                                closable={true}
                                onClose={() => self.handleVisibleAdditionalFilter(false)}
                                width={300}
                                visible={visibleAdditionalFilter}>
                                <Suspense fallback="">
                                    <AdditionalFilter
                                        variables={variables}
                                        spinning={loading.filter || loading.articles}
                                        handle={self.handleAdditionalFilter}
                                        dataToSearch={dataToSearch}
                                        dataSource={additionalFilter}/>
                                </Suspense>
                            </Drawer>
                        )}
                    </Row>
                    {!isMobile?(
                        <BackTop>
                            <div className="backToTop">
                                <UpOutlined />
                            </div>
                        </BackTop>
                    ):null}
                </div>
            </>
        )
    }
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
        appLanguage: store.state.appLanguage,
        variables: store.state.variables,
        options: store.state.options,
    };
}

export default connect(mapState)(SearchResultScreen);
