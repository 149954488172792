import {isMobile} from "react-device-detect";
import {InterfaceInitialState, IVariables, ILanguages, IMenu, ISetting,
ILanguageVariables, IOptions} from "./index.d";
import {post} from "../senders";
import DB from "store/DB";

const initialState: InterfaceInitialState = {
    isMobile,
    appLanguage: "ru",
    languages: {},
    languageVariables: {},
    variables: {},
    options: {},
    menu: [],
    setting: {
        fb: "",
        vk: "",
        tw: "",
        whatsapp: ""
    },
}

const state = {
	state: initialState,
	reducers: {
		update(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
		initLanguages(payload: any, rootState: any) {
            const {callback} = payload,
            storage = rootState.state;

            const getLanguagesData = (appLanguage: string) => {
                new Promise((resolve: any) => {
                    post('/api/getLanguagesData', null, (response: any, error: boolean) => {
                        if (!error && response?.languages && response?.variables) {
                            let languages: ILanguages[] = response.languages,
                            languageVariables: ILanguageVariables = response.variables,
                            variables: IVariables = response.variables[appLanguage]?response.variables[appLanguage]:{};

                            dispatch.state.update({languages, languageVariables, variables, appLanguage});
                            resolve(true)
                        } else resolve(false)
                    });
                }).then((data: any) => {
                    if (callback) callback(data)
                })
            }

            DB._retrieveData("appLanguage")
            .then(({jsonstr, status}) => {
                if (status) {
                    try {
                        dispatch.state.update({appLanguage: jsonstr}).then(() => {
                            if (jsonstr) getLanguagesData(jsonstr)
                            else getLanguagesData(storage.appLanguage)
                        })
                    } catch (e) {
                        getLanguagesData(storage.appLanguage)
                    }
                } else getLanguagesData(storage.appLanguage)
            });
        },
		initHeader(payload: any, rootState: any) {
            const {callback} = payload;
            new Promise((resolve: any) => {
                post('/api/getHeaderData', null, (response: any, error: boolean) => {
                    if (!error && response?.menu && response?.setting) {
                        let menu: IMenu[] = response.menu;
                        let setting: ISetting = response.setting;
                        let options: IOptions = response.options;

                        dispatch.state.update({menu, setting, options});
                        resolve(true)
                    } else resolve(false)
                })
            }).then((data: any) => {
                if (callback) callback(data)
            })
        },
		changeAppLanguage(payload: any, rootState: any) {
            const {data} = payload,
            storage = rootState.state;
            // Сохраняем в стор
            DB._storeData("appLanguage", data);

            let appLanguage: string = data,
            variables: IVariables = storage.languageVariables[appLanguage]?storage.languageVariables[appLanguage]:{};

            dispatch.state.update({appLanguage, variables}).then(() => {
                post('/api/getLanguageOptions', {appLanguage: data}, (response: any, error: boolean) => {
                    if (!error && response?.options) {
                        let options: IOptions = response.options;
                        dispatch.state.update({options});
                    }
                })
            })
        },
    })
}

export default state;
