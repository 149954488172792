import React from "react";
import {Link} from "react-router-dom";
import {Row, Col, Typography, List} from "antd";
import {RelatedArticles, Article} from "./index.d";

type Props = {
    isMobile: boolean,
    loading: boolean,
    articleHeight: number,
    article: Article,
    articles: RelatedArticles[],
}

const {Title} = Typography,
RenderRow: React.FC<Props> = ({
    isMobile, loading, articleHeight,
    article, articles, children
}) => {
    if (isMobile) return (
        <Row className="mt-15">
            <Col span={24}>{children}</Col>
        </Row>
    );
    else {
        return (
            <Row>
                {articles.length>1?null:(
                    <Col span={3} />
                )}
                <Col span={18} className="pr-10">
                    {children}
                </Col>
                {articles.length > 1? (
                    <Col span={6}>
                        <div className="section-box pt-0 pb-0 pr-0">
                            <div className="related-articles-content" style={{height: articleHeight}}>
                                {article.journalPart?(
                                    <Link
                                        target="_blank"
                                        to={`/project/${article.journalId}/index`}>
                                        <Title level={4} className="pt-25 mb-0 title-color">{article.journalPart}</Title>
                                    </Link>
                                ):null}
                                <List
                                    className="related-articles"
                                    loading={loading}
                                    dataSource={articles}
                                    renderItem={(item: RelatedArticles, idx: number) => {
                                        let to = `/project/${item.id_journal}/object/${item.id}`;
                                        if (item.is_index) to = `/project/${item.id}/index`
                                        return (
                                            <List.Item key={idx}>
                                                <Link
                                                    className={"related-articles-single" + ((item.id === article.id)?" active":"")}
                                                    title={item.title}
                                                    to={to}>
                                                    {idx+1}. {item.title}
                                                </Link>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                ): <Col span={3} />}
            </Row>
        )
    }
}

export default RenderRow;
