import React from "react";
import {Form, Input, Row, Col, Button} from "antd";
import {SearchOutlined} from "@ant-design/icons";

import {SearchFormProps, initialFormValues} from "./index.d";
import FilterRow from "./row";
const {Search} = Input;

const SearchForm:React.FC<SearchFormProps> = ({
    isMobile, variables, options, sending, dataToSearch, handleSubmit, language
}) => {
    const [form] = Form.useForm(),
    [strSearch, setStrSearch] = React.useState(""),
    submit = (values: any): void => {
        if (handleSubmit) handleSubmit(values)
    },
    handleAdvancedSearch = () => {
        const advancedSearch: boolean = !form.getFieldValue("advancedSearch");
        let string = "";
        if (advancedSearch) setStrSearch(form.getFieldValue("string"));
        else {
            string = strSearch;
            setStrSearch("")
        }
        form.setFieldsValue({advancedSearch, string})
    },
    getInitialFormValues = () => dataToSearch?dataToSearch:initialFormValues;

    return (
        <Form
            form={form}
            onFinish={submit}
            initialValues={getInitialFormValues()}>
            <Row>
                <Form.Item name="advancedSearch">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="rows">
                    <Input type="hidden" />
                </Form.Item>
                <Col span={24}>
                    <Form.Item className="mb-0" shouldUpdate>
                        {() => {
                            let fieldAdvancedSearch: boolean = form.getFieldValue('advancedSearch');
                            return (
                                <Form.Item
                                    className="mb-10"
                                    name="string">
                                    <Search
                                        placeholder={variables.findAnArticle + "..."}
                                        onSearch={() => form.submit()}
                                        enterButton={true}
                                        disabled={sending || fieldAdvancedSearch}
                                        size="large"/>
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </Col>
                <Col span={24} className="text-right">
                    <Form.Item className="mb-0" shouldUpdate>
                        {() => {
                            let fieldAdvancedSearch: boolean = form.getFieldValue('advancedSearch')
                            return (
                                <Button
                                    disabled={sending}
                                    type={fieldAdvancedSearch?"primary":"default"}
                                    onClick={handleAdvancedSearch}>
                                    {fieldAdvancedSearch?variables.hideAdvancedSearch:variables.advancedSearch}
                                </Button>
                            )
                        }}
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item className="mt-10 mb-10" shouldUpdate>
                {() => {
                    let fieldAdvancedSearch: boolean = form.getFieldValue('advancedSearch')
                    if (fieldAdvancedSearch) {
                        return (
                            <>
                                <FilterRow
                                    isMobile={isMobile}
                                    form={form}
                                    options={options}
                                    sending={sending}
                                    variables={variables}
                                    language={language}
                                    />
                                <Row>
                                    <Col span={24} className={`${(isMobile?"pt-10":"pt-20")} text-center pb-30`}>
                                        <Button
                                            loading={sending}
                                            icon={<SearchOutlined />}
                                            htmlType="submit"
                                            size="large"
                                            type="primary">
                                            {variables.search}
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )
                    } else return null;
                }}
            </Form.Item>
        </Form>
    )
}

export default SearchForm;
