import {IVariables, IOptions} from "store/models/index.d"
import {IRow} from "components/screens/searchScreen/extra/index.d";
import {IAFilter} from "./extra/additionalFilter";
import {IArticles} from "./extra/articles";

export interface Props {
    isMobile: boolean,
    appLanguage: string,
    variables: IVariables,
    options: IOptions,
    history: any,
    abort: any,
    params: {
        data: string
    }
}

export interface IDataToSearch {
    string: string,
    advancedSearch: boolean,
    rows: IRow[],
    additionalFilter: any,
    page: number
}

export interface ICancelTokens {
    [index: string]: any[]
}

export interface IFoundArticle {
    id: number,
    weight: number
}

export interface ILoadding {
    articles: boolean,
    filter: boolean,
}

export type State = {
    visibleAdditionalFilter: boolean,
    dataToSearch: IDataToSearch,
    articlesData: IArticles,
    additionalFilter: IAFilter[],
    documentReady: boolean,
    loading: ILoadding,
    cancelTokens: ICancelTokens,
    foundArticles: IFoundArticle[]
}

export const defState = {
    visibleAdditionalFilter: false,
    dataToSearch: {
        string: "",
        advancedSearch: false,
        rows: [],
        additionalFilter: {},
        page: 1
    },
    additionalFilter: [],
    articlesData: {
        articles: [],
        totalCount: 0,
        pageSize: 10
    },
    documentReady: false,
    loading: {
        articles: true,
        filter: true,
    },
    cancelTokens: {},
    foundArticles: []
}
