import React, {Suspense} from "react";
import {connect} from "react-redux";
import {ArticlesProps} from "./index.d";

const DesktopArticles = React.lazy(() => import("./desktop")),
MobileArticles = React.lazy(() => import("./mobile"));

const Articles: React.FC<ArticlesProps> = (props) => {
    if (props.isMobile) return (
        <Suspense fallback="">
            <MobileArticles {...props} />
        </Suspense>
    )
    else return (
        <Suspense fallback="">
            <DesktopArticles {...props} />
        </Suspense>
    )
}

const mapState = (store: any) => {
    return {
        isMobile: store.state.isMobile,
    };
};
export default connect(mapState)(Articles);
