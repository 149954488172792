import React from 'react'
import {useParams, useHistory} from "react-router-dom"

interface ChildProps {
    component: any,
}

const abort = (history: any, errorCode?: number): void => {
    history.replace("/")
}

const Child = ({component}: ChildProps) => {
    let params = useParams();
    let history = useHistory()

    const props = {
        component,
        params,
        history,
        abort: (errorCode?: number) => abort(history, errorCode)
    }
    const Component = component;

    return <Component {...props} />
}

export default Child;
